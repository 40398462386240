import React, { useState } from 'react'
import ModalContainer from '../ModalContainer'
import { FaCircleXmark } from 'react-icons/fa6';
import { Button, Title } from '../CustomComponents';
import { INPUT_TYPES } from '../../Constant/InputTypesAndPatternsConstant';
import { SVG } from '../../Constant/SvgConstant';
import { Spinner } from 'react-bootstrap';

export default function DeleteModal({
    title = '',
    description = '',
    cancelText = '',
    submitText = '',
    isDeleting = false,
    handleClose = () => { },
    handleSubmit = () => { }
}) {

    const [modalClass, setModalClass] = useState('deleteModal scale-in-hor-center');

    const handleModalCloseWithAnimation = () => {
        setModalClass('addForumModalContainer scale-out-horizontal');
        setTimeout(() => {
            handleClose();
        }, 500);
    };

    return (
        <div>
            <ModalContainer
                handleClose={handleModalCloseWithAnimation}
            >
                <div className={modalClass}>

                    <div className='d-flex align-items-center w-100 justify-content-between'>

                        <div className={'closeBtn'}>
                            <FaCircleXmark fontSize={40} color={'#F68B28'} onClick={handleModalCloseWithAnimation} />
                        </div>
                    </div>

                    <div className='d-flex flex-column gap-3 align-items-center'>
                        <img src={SVG.BIN_ICON} alt="Bin Icon" width={80} height={80} />

                        <Title text={title} />
                        <p className="ant-upload-text m-0">{description}</p>

                        <div className='w-100 d-flex flex-row gap-3'>
                            <Button
                                classes="scale-in-center-slow fs-5 mt-1 w-100"
                                text={cancelText}
                                type={INPUT_TYPES.SUBMIT}
                                onClick={handleModalCloseWithAnimation}
                                bordered={true}
                            />
                            <Button
                                classes={` scale-in-center-slow fs-5 mt-1 w-100`}
                                text={isDeleting ? <Spinner className={"spinner"} /> : submitText}
                                type={INPUT_TYPES.SUBMIT}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>

                </div>

            </ModalContainer>
        </div>
    )
}
