import _ from "lodash";
import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { EmptyContainer } from "../../../Components/CustomComponents";
import { POST_TYPE_ENUM } from "../../../Constant/constants";
import { SVG } from "../../../Constant/SvgConstant";
import { useGetMyPurchaseQuery } from "../../../Data/services/userApi";
import { useLocalization } from "../../../Hooks/useLocalization";
import { ROUTES } from "../Route";
import styles from "../styles/index.module.css";
import EditWorkoutTemplateModal from "../../../Components/Modals/EditWorkoutTemplateModal";

export default function MyPurchase({ setPage }) {
  const STRING = useLocalization();
  const [editTemplateData, setEditTemplateData] = useState(undefined);
  

  const { data, isFetching, refetch } = useGetMyPurchaseQuery({
    refetchOnMountOrArgChange: true,
  });

  const handlePostClick = (details) => {
    setPage({
      route: !!details.is_paid ? ROUTES.PREMIUM_POST : ROUTES.POST,
      prev_route: STRING["MyPurchase"],
      data: details,
    });
  };

  const handleWorkoutClick = (details) => {
    setPage({
      route: !!details.is_paid ? ROUTES.PREMIUM_WORKOUT : ROUTES.WORKOUT,
      prev_route: STRING["MyPurchase"],
      data: details,
    });
  };

  const handleTemplateClick = (details) => {
    // console.log(details);
    setPage({
      route: !!details.is_paid ? ROUTES.PREMIUM_TEMPLATE : ROUTES.TEMPLATE,
      prev_route: STRING["MyPurchase"],
      data: details,
    });
  };


  const handleChangeAndRestoreTemplate = (e, item) => {
    setEditTemplateData(item)
    // console.log(item);
    e.stopPropagation()
  }

  const handleEditTemplateClose = () => {
    setEditTemplateData(undefined)
  }


  return (
    <React.Fragment>
      {_.isUndefined(editTemplateData) ? "" : <EditWorkoutTemplateModal template_slug={editTemplateData.slug} data={editTemplateData} handleModalClose={handleEditTemplateClose} />}
      <Row>
        <Col xs={12}>
          <h4>{STRING.PostAndVideosLabel}</h4>
        </Col>
        <Col xs={12} md={12} className="p-0">
          {isFetching ? (
            <div className="w-100 d-flex justify-content-center pt-4 pb-3 text-light">
              <Spinner color="white" />
            </div>
          ) : _.isEmpty(data?.data?.video) ? (
            <EmptyContainer text={STRING.PostNotFound} />
          ) : (
            <div className="d-flex flex-wrap">
              <div className="grid_post">
                {data?.data?.video.map((item, index) => (
                  <Videos
                    item={item}
                    key={item.slug}
                    handleClick={() => handlePostClick(item)}
                  />
                ))}
              </div>
            </div>
          )}
        </Col>
      </Row>

      <Row className="g-3 mt-2">
        <Col xs={12}>
          <h4>{STRING.WorkoutLabel}</h4>
        </Col>
        {isFetching ? (
          <div className="w-100 d-flex justify-content-center pt-4 pb-5 text-light">
            <Spinner color="white" />
          </div>
        ) : _.isEmpty(data?.data?.workout) ? (
          <EmptyContainer text={STRING.WorkoutNotFound} />
        ) : (
          data?.data?.workout.map((item, index) => (
            <Col xs={12} md={6} key={index}>
              <WorkoutView
                item={item}
                key={item.slug}
                handleClick={() => handleWorkoutClick(item)}
              />
            </Col>
          ))
        )}
      </Row>

      <Row className="g-3 mt-4">
        <Col xs={12}>
          <h4>{STRING.TemplateLabel}</h4>
        </Col>
        {isFetching ? (
          <div className="w-100 d-flex justify-content-center pt-4 pb-5 text-light">
            <Spinner color="white" />
          </div>
        ) : _.isEmpty(data?.data?.template) ? (
          <EmptyContainer text={STRING.TemplateNotFound} />
        ) : (
          data?.data?.template.map((item, index) => (
            <Col xs={12} md={6} key={index}>
              <Templates
                item={item}
                key={item.slug}
                handleClick={() => handleTemplateClick(item)}
                handleChangeAndRestoreTemplate={(e) => handleChangeAndRestoreTemplate(e, item)}
              />
            </Col>
          ))
        )}
      </Row>
    </React.Fragment>
  );
}

const Videos = ({ item, handleClick }) => {
  const isVideo = item.type === POST_TYPE_ENUM.VIDEO;

  return (
    <div
      style={{ position: "relative", cursor: "pointer" }}
      onClick={handleClick}
    >
      {!isVideo ? (
        <img alt="My post" src={item.image_url} />
      ) : (
        <img alt="My post" src={item.thumbnail_url} />
      )}
      <div className={styles.lightPlayBtn}>
        {isVideo && <img src={SVG.LIGHT_PLAY_ICON} alt="" />}
      </div>
    </div>
  );
};

const WorkoutView = ({ item, handleClick }) => {
  return (
    <div className="workoutContainer" role="button" onClick={handleClick}>
      <div className="imageWrapper">
        <img src={item.image_url} alt="" className={`image `} />
        {/* ${premium ? "premiumImage" : ""} */}
        <div className="gradientOverlay"></div>
      </div>

      {/* {item.is_paid ? <PremiumTxt /> : ""} */}

      {/* <FreeTag text={"Free"} /> */}

      <p className="title lc-1 m-0 fs-5 mb-5"> {item.title} </p>
      <div className={styles.workout_styles}>
        {item.tags?.map((tag, index) => (
          <div className={`scale-in-center ${styles.tag_btn}`} key={index}>
            {tag}
          </div>
        ))}
      </div>
    </div>
  );
};

const PremiumTxt = () => {
  const STRING = useLocalization();
  return (
    <div className={`scale-in-center ${styles.premium}`}>{STRING.Premium}</div>
  );
};

const Templates = ({ item, handleClick, handleChangeAndRestoreTemplate }) => {
  return (
    <div className="templateContainer " onClick={handleClick} role="button">
      <div className="w-100 h-100 d-flex align-items-end gap-2 ps-3 pb-3">
        <h3 className="title lc-1 m-0 fs-4 flex-wrap ">{item.name}</h3>
        {/* <p className={`lc-2 m-0 fs-6 ${styles.description}`}> {item} </p> */}
        <div
          className={`scale-in-center ${styles.edit_icon}`}
          onClick={handleChangeAndRestoreTemplate}
        >
          <img src={SVG.EDIT_ICON} alt="" />
        </div>
      </div>
    </div>
  );
};
