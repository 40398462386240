import React from "react";
import {  TimePicker } from "antd";
import dayjs from "dayjs";
import "./timeFieldComponent.css";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const format = 'HH:mm:ss';

const TimeFieldComponentAntD = ({ placeholder, onChange, value }) => {
  // const [value, setValue] = React.useState(dayjs('2022-04-17'));

  return (
    <div
      style={{
        width: "100%",
        // display: 'flex',
        // justifyContent: 'flex-start',
        // position: 'relative',
      }}
      onClick={() => {}}
    >
        <TimePicker
        placeholder={placeholder}
          onChange={onChange}
          value={value ? dayjs(value, format) : null}
          // value={value}
          defaultOpenValue={dayjs(value ? value : '00:00:00', format)}
          className="custom-timepicker my-1"
          changeOnScroll needConfirm={false}
        />
    </div>
  );
};

export default TimeFieldComponentAntD;
