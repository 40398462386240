import React from "react";
import { SVG } from "../../Constant/SvgConstant";

import styles from "./PostContainer.module.scss"
import { BinIcon, PremiumSign } from "./index";
import { Spinner } from "react-bootstrap";

const PostContainer = ({ image = '', thumbnail = "", is_paid = false, is_purchased = false, is_mine = false, is_deleteable = false, isDeleting = false, handleDelete = () => { } }) => {
    return (
        <div className={styles.imageWrapper}>
            {thumbnail !== "" ? (
                <div className="w-100 h-100 thumbnail-container">
                    {
                        is_paid ?
                            <PremiumSign />
                            : ''
                    }
                    <img src={thumbnail} alt="" className={`${styles.image}`} />
                    <span >
                        <img src={SVG.PLAY_ICON} alt="Play Sign" className="play-icon" />
                    </span>
                </div>

            ) : (
                <>
                    {
                        is_paid ?
                            <PremiumSign className="zIndex-1" />
                            : ''
                    }
                    {
                        is_deleteable ?
                            <BinIcon className="zIndex-1" onClick={handleDelete} isDeleting={isDeleting} />
                            : ''
                    }
                    <img src={image} alt="" className={`${styles.image} ${(is_paid && !is_purchased && !is_mine) ? 'blur' : ""}`} />
                </>
            )
            }
        </div >
    );
};

export default PostContainer;
