import React, { useEffect, useState } from "react";

import { Col, Spinner } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useGetWorkoutQuery } from "../../../Data/services/dashboardApi";
import HorizontalCard from "./HorizontalCard";
import GridCard from "./GridCard";

export default function WorkoutListing({
  selectedValue = "",
  setPage = () => {},
}) {
  const LIMIT = 18;
  const search = useSelector((state) => state.search.text);

  const is_paid = !selectedValue ? "" : selectedValue === "paid";
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [skip, setSkip] = useState(false);
  const [isFetchedFirstTime, setIsFetchedFirstTime] = useState(false);

  const { data, isFetching } = useGetWorkoutQuery(
    { is_paid, page: currentPage, search, limit: LIMIT, offset },
    {
      skip: skip,
      refetchOnMountOrArgChange: false,
    }
  );

  const listingData = useSelector(
    (state) =>
      state.dashboard.workout[!selectedValue ? "all" : selectedValue].data
  );

  // MARK: USE EFFECT
  useEffect(() => {
    if (isFetching) return;
    setIsFetchedFirstTime(true);
    setSkip(true);

    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records);
    } else if (data.links?.total_records > total) {
      setTotal(data.links.total_records);
      setOffset((prev) => prev + (data.links?.total_records - total));
    }
  }, [isFetching]);

  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage((prev) => prev + 1);
      setSkip(false);
    }
  }, [inView]);

  useEffect(() => {
    if (!isFetchedFirstTime) return;
    const timeout = setTimeout(() => {
      setCurrentPage(1);
      setOffset(0);
      setTotal(0);
      setSkip(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <>
      {(listingData ?? []).map((item, index) => (
        <Col
          lg={4}
          md={4}
          sm={6}
          className="mt-2 mb-2 focus-in-expand-slow"
          key={`${!!item.is_purchased ? "purchased" : "not-puchased"}-${
            item.slug
          }`}
          ref={
            listingData.length >= total - offset
              ? null
              : index === listingData.length - 1
              ? ref
              : null
          }
        >
          {console.log(item)}

          <HorizontalCard data={item} setPage={setPage} />
        </Col>
      ))}
      {isFetching ? (
        <div className="w-100 d-flex justify-content-center py-4 text-light">
          <Spinner color="white" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
