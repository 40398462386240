export const SVG = {
    GOOGLE: '/svg_images/google_svg_icon.svg',
    FB: '/svg_images/fb_svg_icon.svg',
    INSTAGRAM: '/svg_images/instagram_svg_icon.svg',
    DASHBOARD: '/svg_images/dashboard_svg_icon.svg',
    COMMUNITY: '/svg_images/community_svg_icon.svg',
    MENU: '/svg_images/menu_svg_icon.svg',
    TEAM: '/svg_images/team_svg_icon.svg',
    SETTINGS: '/svg_images/setting_svg_icon.svg',
    PAYMENT: '/svg_images/payment_svg_icon.svg',
    PRIVACY: '/svg_images/privacy_svg_icon.svg',

    PLAY_ICON: '/svg_images/play_svg_icon.svg',
    CROWN_ICON: '/svg_images/crown_svg_icon.svg',
    EDIT_ICON: '/svg_images/edit_svg_icon.svg',
    LIGHT_PLAY_ICON: "/svg_images/light_play_svg_icon.svg",
    BIN_ICON: "/svg_images/bin.png",

}
