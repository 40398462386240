import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { EmptyContainer } from "../../../Components/CustomComponents";
import PostContainer from "../../../Components/CustomComponents/PostContainer";
import { useDeletePostMutation, useGetMyPostQuery } from "../../../Data/services/userApi";
import { ROUTES } from "../Route";
import { useLocalization } from "../../../Hooks/useLocalization";
import DeleteModal from "../../../Components/Modals/DeleteModal";

function MyPost({ setPage }) {
  const STRING = useLocalization()

  const [deletePost] = useDeletePostMutation();


  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [deleteItemSlug, setDeleteItemSlug] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);


  const { data, isFetching } = useGetMyPostQuery({ page: currentPage, limit: 24, offset }, {
    refetchOnMountOrArgChange: true,
  });

  const listingData = useSelector(state => state.user.my_post.data)

  // MARK: USE EFFECT
  useEffect(() => {
    if (isFetching) return;
    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records)
    }
    else if (data.links?.total_records > total) {
      setTotal(data.links.total_records)
      setOffset(prev => prev + (data.links?.total_records - total))
    }
  }, [isFetching])

  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage(prev => prev + 1);
    }
  }, [inView])



  const handlePageView = (details) => {
    setPage({
      route: !!details.is_paid ? ROUTES.PREMIUM_POST : ROUTES.POST,
      prev_route: STRING['MyPosts'],
      data: details
    });

  };


  const handleModalClose = () => {
    setDeleteItemSlug(undefined)
  }


  const handleDelete = async () => {
    if (isDeleting) return;
    try {
      setIsDeleting(true);
      await deletePost(deleteItemSlug).unwrap();
      setDeleteItemSlug(undefined);
      setIsDeleting(false);
    } catch (e) {
      setDeleteItemSlug(undefined)
      setIsDeleting(false);
    }

  }

  return (
    <>
      {_.isUndefined(deleteItemSlug) ? "" :
        <DeleteModal
          title={STRING.DeletePost}
          description={STRING.AreYouSureYouWantToDeleteThisPost}
          cancelText={STRING.No}
          submitText={STRING.Yes}
          isDeleting={isDeleting}
          handleClose={handleModalClose}
          handleSubmit={handleDelete}
        />
      }
      {(_.isEmpty(listingData) && !isFetching) ? <EmptyContainer text={STRING.PostNotFound} /> :
        <Row className="g-3">
          {
            (listingData ?? []).map((item, index) => (
              <Col
                key={item.slug}
                xl={2}
                md={3}
                xs={6}
                ref={
                  (listingData.length >= (total - offset)) ? null :
                    (index === (listingData.length - 1)) ? ref : null}
                onClick={() => handlePageView(item)}
                className="cursor-pointer"
              >

                <PostContainer
                  key={index}
                  image={item.image_url}
                  thumbnail={item.thumbnail_url}
                  is_paid={item.is_paid}
                  is_purchased={true}
                  is_mine={true}
                  is_deleteable={true}
                  isDeleting={isDeleting}
                  handleDelete={(e) => {
                    e.stopPropagation();
                    setDeleteItemSlug(item.slug)
                  }}
                />
              </Col>
            ))
          }
        </Row >
      }
      {isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> : ""}
    </>
  );
}

export default MyPost;
