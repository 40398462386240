export const END_POINTS = {
  AUTH: {
    REGISTER: "",
    CREATE_CUSTOMER: "stripe/create-customer",
    VERIFY_OTP_AFTER_REGISTER: "verify-otp/register",
    LOGIN: "login",
    UPDATE: "",
    CHANGE_PASSWORD: "change-password",
    SEND_OTP: "send-otp/mail",
    VERIFY_OTP_FORGOT_PASSWORD: "verify-otp/forgot-password",
    SET_NEW_PASSWORD: "set-password",
    NOTIFICATION: "update-pushnotification"
  },
  CARD: {
    ADD: "card/create-user-card",
    GET: "card/get-user-card",
    REMOVE: (id) => "card/delete-user-card/" + id,
  },
  PAYMENT: {
    CREATE: "stripe/create-payment"
  },
  USER: {
    ADD_USER: "add_user",
    UPDATE_USER: "update_user",
    DELETE_USER: "delete_user",
    GET_USERS: "search",
    GET_REMAINING_USERS: "group",
    GET_PROFILE: "",
    CONNECT_ACCOUNT: "connect-account",
    GET_FOLLOWER: "profile/follower",
    GET_FOLLOWING: "profile/following",
    GET_POST: "post",
    DELETE_POST: (slug) => "media/" + slug,
    GET_WORKOUT: "workout",
    GET_TEMPLATE: "template",
    GET_MY_PURCHASE: "home/getvideosandworkout",
    RESTORE_MEDIA: (slug) => `media/${slug}/restore`,
    GET_SHARED_POST: "post/share",
    GET_POST_COMMENT: "post/getcomments/",
    SEND_POST_COMMENT: "post/createcomment",
    LIKE_POST: "post/like/",
    REMOVE_POST_LIKE: "post/like/",
    SAVE_POST: "post/save/",
    UNSAVE_POST: "post/save/",
  },
  OTHER_USER: {
    GET_PROFILE: (slug) => `/${slug}`,
    GET_POST: (slug) => `/${slug}/post`,
    GET_WORKOUT: (slug) => `/${slug}/workout`,
    FOLLOW_USER: (slug) => `/profile/follow/${slug}`,
    UNFOLLOW_USER: (slug) => `/profile/unfollow/${slug}`,
    GET_FOLLOWER: (slug) => `/profile/${slug}/follower`,
    GET_FOLLOWING: (slug) => `/profile/${slug}/following`,
  },
  Dashboard: {
    CREATE_POST: "post",
    CREATE_WORKOUT: "workout",
    UPDATE_WORKOUT: "workout/",
    CREATE_FORUM: "community/forum",
    CREATE_TEMPLATE: "template",
    UPDATE_TEMPLATE: (slug) => "template/" + slug,
    GET_ALL: "home",
    GET_POST: "home/post",
    GET_POST_DETAILS: "post/",
    GET_WORKOUT: "home/workout",
    GET_WORKOUT_DETAILS: "workout/",
    GET_TEMPLATE: "home/template",
    GET_TEMPLATE_DETAILS: "template/",
    DOWNLOAD_WORKOUT: (slug) => "workout/" + slug + '/download',
    DOWNLOAD_TEMPLATE: (slug) => "template/" + slug + '/download',
  },
  Community: {
    GET_FOLLOWING: "community/followings",
    GET_FORYOU: "community/for-you",
    GET_POST_COMMENT: "post/getcomments/",
    SEND_POST_COMMENT: "post/createcomment",
    SHARE_POST: (slug) => "post/" + slug + "/share",
    GET_FORUM: "community/forum",
    GET_FORUM_COMMENT: "community/forum/",
    SEND_FORUM_COMMENT: "community/forum/createcomment",
    SHARE_FORUM: (slug) => "community/forum/" + slug + "/share",
    LIKE_POST: "post/like/",
    REMOVE_POST_LIKE: "post/like/",
    SAVE_POST: "post/save/",
    UNSAVE_POST: "post/save/",
    REPORT_POST: "post/report",
  },
  TRAINING: {
    GET_ACTIVE_LOG: 'logs/active',
    UPDATE_LOG: 'logs',
    LOGS: (type, date) => 'logs?type=' + type + "&datetime=" + date
  },
  LOOKUP: "lookups",
  LOOKUP_SPORTS: "lookups/sports",
  STATIC_CONTENT: {
    PRIVACY: "settings/get-content/PrivacyPolicy",
    CONDITION: "settings/get-content/TermsAndCondition",
    CONTACT_US: "settings/get-content/ContactUs",
  },
  SUBMIT_FEEDBACK: "feedback/create-feedback",
  BADGE: "achievement",
  WORKOUT_DAYS: "logs/days"
};
