import React, { useContext, useEffect } from "react";
import socket from "../config/Socket";
import { useDispatch, useSelector } from "react-redux";
import { logout, socketConntected } from "../Data/Features/authSlice";
import { removeToken } from "../Data/services/localStorageService";

// import { updateKey } from "../features/DefaultReducer";

const SocketContext = React.createContext();

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketProvider({ children }) {
  const { isLoggedIn, user, isSocketConnected } = useSelector(
    (state) => state.authInfo
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(isLoggedIn, user);

    if (isLoggedIn) {
      socket.connectToSocket(
        user?.api_token,
        () => {
          dispatch(logout());
          removeToken();
          // window.location.reload();
        },
        () => {
          dispatch(socketConntected());
        }
      );
    } else {
      socket?.disconnect();
    }
    return () => socket?.disconnect();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isSocketConnected) {
      socket.on("newRoom_", (data) => {
        socket.emit("_joinRoom", data?.data?.chat_room_slug);
      });
    }

    return () => {
      if (isSocketConnected) {
        socket.dispose("newRoom_");
      }
    };
  }, [isSocketConnected]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}
